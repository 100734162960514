/* eslint-disable no-console */
import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import UPDATE_ORGANIZATION from "graphql/mutations/organization/updateOrganization";

import useMe from "hooks/useMe";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";

const useUpdateOrganizationLocation = (location) => {
  const { me } = useMe();

  const [updateOrganization, options] = useMutation(UPDATE_ORGANIZATION);

  const updateOrganizationLocation = useCallback(
    async (locationValues) => {
      const organization = me?.professional?.organization || {};
      const currentLocations = organization?.locations || [];

      if (!organization) {
        return toast.error("You are not a member of any organization!");
      }

      if (!location) {
        return toast.error("Location doesn't exist!");
      }

      const toastId = toast.loading("Updating Location...");

      try {
        const locationWhere = { id: location?.id };

        const orgWhere = { id: organization?.id };
        const orgData = {
          locations: {
            update: {
              where: locationWhere,
              data: assignObjectValuesToSet(locationValues),
            },
          },
        };

        await updateOrganization({
          variables: {
            where: orgWhere,
            data: orgData,
          },
          optimisticResponse: () => {
            const updatedLocation = {
              __typename: "OrganizationLocation",
              ...location,
              ...locationValues,
            };

            const locations = currentLocations.map((locationEl) =>
              locationEl?.id === location?.id ? updatedLocation : locationEl,
            );

            return {
              updateOrganization: {
                __typename: "Organization",
                ...organization,
                locations,
              },
            };
          },
        });

        toast.success("Updated Location");
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        toast.dismiss(toastId);
      }
    },
    [location, me?.professional?.organization, updateOrganization],
  );

  return [updateOrganizationLocation, options];
};

export default useUpdateOrganizationLocation;
