const parseSupportCalculation = (calculation) => {
  if (!calculation) return calculation;

  const { content, calculationResult } = calculation;

  return {
    ...calculation,
    ...(content && JSON.parse(content)),
    calculationResult: {
      ...calculationResult,
      ...(calculationResult && JSON.parse(calculationResult?.content)),
    },
  };
};

export default parseSupportCalculation;
