import React from "react";

import { ApolloProvider } from "@apollo/client";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import "chartjs-plugin-doughnutlabel";
import "chartjs-plugin-deferred";
import "chartjs-plugin-datalabels";

import "styles/index.scss";
import App from "layout/App";

import client from "./apollo";
import store from "./redux";

smoothscroll.polyfill();

ReactDom.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter basename="/app">
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById("react-root"),
);
