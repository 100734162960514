import React, { Fragment, useMemo } from "react";

import { useLocation } from "react-router";

import useMe from "hooks/useMe";

const proTools = [
  "Spousal & Child Support",
  "Unlimited Clients",
  "Complex Income & Tax",
  "Unlimited PDF Reports",
  "Client Interviews",
  "Generate Court Forms & Documents",
  "Promote Your Practice",
];

const FeaturesList = () => {
  const { isPro, isUnpaidSub } = useMe();
  const { pathname } = useLocation();

  const showNegotiate = useMemo(() => pathname.includes("spousal"), [pathname]);

  const span = useMemo(() => {
    if (isUnpaidSub) {
      return 'Your subscription has not been paid or expired. Your account access has been downgraded to the "Free" plan. To upgrade again, choose a plan.';
    }

    if (isPro) {
      return "Start your 7-day trial of Divorcepath Pro Tools. No obligation.";
    }

    return (
      <React.Fragment>
        * Satisfaction Guaranteed. If you&apos;re not completely happy with your upgrade, let us
        know and we&apos;ll issue a{" "}
        <a className="upgrade-plan-link" href="https://www.divorcepath.com/guarantee">
          refund
        </a>
        .
      </React.Fragment>
    );
  }, [isPro, isUnpaidSub]);

  return (
    <div className="upgrade-plan-container">
      <span className="upgrade-plan-span">{span}</span>
      <ul className="upgrade-plan-list">
        {isPro ? (
          proTools.map((tool) => (
            <li className="upgrade-plan-list-item" key={tool}>
              {tool}
            </li>
          ))
        ) : (
          <Fragment>
            {showNegotiate && <li className="upgrade-plan-list-item">Negotiate support amount</li>}
            <li className="upgrade-plan-list-item">All income types</li>
            <li className="upgrade-plan-list-item">Special child-related expenses</li>
            <li className="upgrade-plan-list-item">Guideline income & exceptions</li>
            <li className="upgrade-plan-list-item">Download PDF reports</li>
            {isPro && <li className="upgrade-plan-list-item">Retroactive calculations</li>}
          </Fragment>
        )}
      </ul>
      <ul className="upgrade-plan-list" />
    </div>
  );
};

export default FeaturesList;
