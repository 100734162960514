import { useEffect, useMemo } from "react";

import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import usePrevValue from "hooks/usePrevValue";
import useWindowSize from "hooks/useWindowSize";

const isClientProfileFromPro = (path) => /clients\/.+\/edit/.test(path);

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  const prevPathname = usePrevValue(pathname);

  const { width } = useWindowSize();

  const mobileView = useMemo(() => width <= 768, [width]);

  useEffect(() => {
    const isProfile = pathname.includes("/profile") || isClientProfileFromPro(pathname);
    const isPrevProfile = prevPathname.includes("/profile") || isClientProfileFromPro(prevPathname);

    if (hash === "" && (!isProfile || !isPrevProfile)) {
      scroll.scrollToTop({
        duration: 400,
        delay: 0,
        smooth: "easeInOutQuart",
        ignoreCancelEvents: true,
        isDynamic: true,
      });
    }
  }, [pathname, hash, prevPathname, mobileView]);

  return null;
};

export default ScrollToTop;
