import { Row, Col } from "react-bootstrap";
import React, { ErrorBoundary } from "react-error-boundary";

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

const WAIT_BEFORE_RELOAD = 10;

function fallbackRender({ error, resetErrorBoundary }) {
  const homeURL = "https://www.divorcepath.com";

  if (process.env.NODE_ENV === "production") {
    setTimeout(resetErrorBoundary, WAIT_BEFORE_RELOAD * 1_000);
  }

  return (
    <div
      role="alert"
      className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
    >
      <div className="logo-column">
        <a href={homeURL}>
          <img
            alt="Divorcepath.com"
            src="./img/brand/divorcepath-black.svg"
            style={{ width: 150 * 2, height: 32 * 2 }}
          />
        </a>
      </div>
      <Row className="justify-content-center px-3 py-2 text-center">
        <Col xs={12} md={8} lg={6}>
          <p>
            Apologies for the inconvenience. Our application encountered a glitch. Please take a
            screenshot and contact support. We'll reload in {WAIT_BEFORE_RELOAD} seconds to fix it.
            Thank you for your understanding.
          </p>
        </Col>
      </Row>

      <pre className="bg-dark text-white">{error.message}</pre>
    </div>
  );
}

const MainErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
        clearCacheData();
        const token = localStorage.getItem("authToken");
        localStorage.clear();
        localStorage.setItem("authToken", token);
        window.location.replace("/app");
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default MainErrorBoundary;
