import React from "react";

import Modal from "components/common/Modal";
import LocationForm from "components/organization/location/LocationForm";

import useCreateDocumentFlow from "hooks/useCreateDocumentFlow";
import useMe from "hooks/useMe";
import useModal from "hooks/useModal";

export const CREATE_DOCUMENT_LOCATION_MODAL_NAME = "DOCUMENT_LOCATION";

const CreateDocumentLocationModal = () => {
  const { close, options = {} } = useModal(CREATE_DOCUMENT_LOCATION_MODAL_NAME);

  const { me } = useMe();

  const createDocumentFlow = useCreateDocumentFlow(1, { client: options.client });

  const handleSubmit = () => {
    createDocumentFlow(options);

    close();
  };

  const orgLocations = me?.professional?.organization?.locations || [];

  return (
    <Modal
      autoFocus={false}
      borderedHeader={false}
      size="md"
      name={CREATE_DOCUMENT_LOCATION_MODAL_NAME}
      form={CREATE_DOCUMENT_LOCATION_MODAL_NAME}
      titleComponent={
        <div className="custom-modal-title">
          {orgLocations[0] ? "Edit" : "Add"} your office address
        </div>
      }
      submitButtonTitle="Add"
      showCloseButton
      noFooter
    >
      <LocationForm
        type={orgLocations[0] ? "UPDATE" : "CREATE"}
        formId={CREATE_DOCUMENT_LOCATION_MODAL_NAME}
        onSubmit={handleSubmit}
        defaultLocation={orgLocations[0]}
      />
    </Modal>
  );
};

export default CreateDocumentLocationModal;
