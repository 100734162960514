import { gql } from "@apollo/client";

const LAWYER = gql`
  fragment LAWYER on Lawyer {
    _id
    name
    orgName
    fax
    email
    phone
    postal
    city
    residence {
      _id
      name
      shorthand
    }
    country
    street2
    street1
  }
`;

export default LAWYER;
