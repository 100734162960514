/* eslint-disable no-console */
import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import CLIENT_FRAGMENT from "graphql/fragments/client";
import CREATE_LAWYER from "graphql/sanity/mutations/createLawyer";

const useCreateLawyer = (client) => {
  const [createLawyer, options] = useMutation(CREATE_LAWYER);

  const handleCreateLawyer = useCallback(
    async (values) => {
      const { location = {}, ...restValues } = values;

      const toastId = toast.loading("Creating Lawyer...");

      try {
        await createLawyer({
          variables: {
            data: {
              ...restValues,
              location,
              clientId: client?.id,
            },
          },
          update: (cache, { data }) => {
            cache.writeFragment({
              id: `Client:${client.id}`,
              fragment: CLIENT_FRAGMENT,
              fragmentName: "CLIENT_FRAGMENT",
              data: {
                ...client,
                exLawyer: {
                  ...data.createLawyer,
                },
              },
            });
          },
        });

        toast.success("Created Lawyer");
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        toast.dismiss(toastId);
      }
    },
    [client, createLawyer],
  );

  return [handleCreateLawyer, options];
};

export default useCreateLawyer;
