import { gql } from "@apollo/client";

const ALL_PROVINCE = gql`
  query allProvince @api(name: "sanity") {
    allProvince {
      _id
      name
      shorthand
    }
  }
`;

export default ALL_PROVINCE;
