/* eslint-disable no-console */
import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { uniqueId } from "lodash";
import { toast } from "react-toastify";

import UPDATE_ORGANIZATION from "graphql/mutations/organization/updateOrganization";

import useMe from "hooks/useMe";

const useCreateOrganizationLocation = () => {
  const { me } = useMe();

  const [updateOrganization, options] = useMutation(UPDATE_ORGANIZATION);

  const createOrganizationLocation = useCallback(
    async (locationValues) => {
      const organization = me?.professional?.organization || {};
      const currentLocations = organization?.locations || [];

      if (!organization) {
        return toast.error("You are not a member of any organization!");
      }

      const toastId = toast.loading("Creating Location...");

      try {
        const where = { id: organization?.id };
        const data = { locations: { create: [locationValues] } };

        await updateOrganization({
          variables: { where, data },
          optimisticResponse: () => {
            const tempLocation = {
              __typename: "OrganizationLocation",
              id: uniqueId(),
              residence: null,
              street1: null,
              street2: null,
              city: null,
              email: null,
              fax: null,
              website: null,
              phone: null,
              postal: null,
              googleReviewLink: null,
              ...locationValues,
            };

            return {
              updateOrganization: {
                __typename: "Organization",
                ...organization,
                locations: [...currentLocations, tempLocation],
              },
            };
          },
        });

        toast.success("Created Location");
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        toast.dismiss(toastId);
      }
    },
    [me?.professional?.organization, updateOrganization],
  );

  return [createOrganizationLocation, options];
};

export default useCreateOrganizationLocation;
