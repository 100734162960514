import { gql } from "@apollo/client";

import FINANCES from "graphql/fragments/finances";

const UPSERT_FINANCES = gql`
  ${FINANCES}
  mutation upsertFinances($where: FinancesWhereTaxYearInput!, $data: UpdateFinancesInput!) {
    upsertFinances(where: $where, data: $data) {
      ...FINANCES
    }
  }
`;

export default UPSERT_FINANCES;
