import React, { useMemo } from "react";

import { omit } from "lodash";

import useCreateOrganizationLocation from "graphql/hooks/organization/location/useCreateOrganizationLocation";

import CourtForm from "components/ClientProfile/forms/CourtForm";
import Modal from "components/common/Modal";

import useCourtInformation from "hooks/me/useCourtInformation";
import useConfirm from "hooks/useConfirm";
import useCreateDocumentFlow from "hooks/useCreateDocumentFlow";
import useMe from "hooks/useMe";
import useModal from "hooks/useModal";

export const CREATE_DOCUMENT_COURT_MODAL_NAME = "DOCUMENT_INFO";

const CreateDocumentCourtModal = () => {
  const { me } = useMe();
  const { close, options = {} } = useModal(CREATE_DOCUMENT_COURT_MODAL_NAME);

  const [createOrganizationLocation] = useCreateOrganizationLocation();

  const { courtId, courtFile, courtLocationId, residence } = useCourtInformation(options?.client);

  const createDocumentFlow = useCreateDocumentFlow(2, { client: options.client });

  const confirm = useConfirm();

  const orgLocations = me?.professional?.organization?.locations || [];

  const hideFields = useMemo(() => {
    const fields = [];

    if (courtId) fields.push("courtId");
    if (courtFile) fields.push("courtFile");
    if (courtLocationId) fields.push("courtLocationId");
    if (residence) fields.push("residence");

    return fields;
  }, [courtFile, courtId, courtLocationId, residence]);

  const handleSubmit = (values) => {
    createDocumentFlow(options);

    if (orgLocations.length === 0) {
      confirm({
        title: "Do you want to save it to the organization as your default court settings?",
        message: "After saving, you will never have to select court again when creating a document",
        positiveBtnLabel: "Yes",
        negativeBtnLabel: "No",
        onSubmit: () =>
          createOrganizationLocation(omit(values, "courtFile", "isApplicant", "isPlaintiff")),
      });
    }

    close();
  };

  return (
    <Modal
      size="sm"
      name={CREATE_DOCUMENT_COURT_MODAL_NAME}
      titleComponent={<div className="custom-modal-title">Provide Court Information</div>}
      className="create-document"
      noFooter
      showCloseButton
    >
      <CourtForm
        user={{ client: options?.client }}
        hideToggles
        hideFields={hideFields}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default CreateDocumentCourtModal;
