import { gql } from "@apollo/client";

import EX_LAWYER from "graphql/fragments/exLawyer";

const CREATE_LAWYER = gql`
  ${EX_LAWYER}
  mutation createLawyer($data: CreateLawyerInput!) {
    createLawyer(data: $data) {
      ...EX_LAWYER
    }
  }
`;

export default CREATE_LAWYER;
