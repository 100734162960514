import { gql } from "@apollo/client";

const POSSESSION_FRAGMENT = gql`
  fragment POSSESSION on Possession {
    id
    excluded
    acquiredDate
    dispositionDate
    address
    confidence
    description
    institution
    accountNumber

    type
    typeAlias

    occupiedBy
    ownership
    clientId

    claimedByClient
    claimedByClientOnValuationDate
    claimedByClientOnDateOfMarriage
    claimedByClientOnDateOfAcquisition
    claimedByEx
    claimedByExOnValuationDate
    claimedByExOnDateOfMarriage
    claimedByExOnDateOfAcquisition

    exValuationClaimedByClient
    exValuationClaimedByClientOnValuationDate
    exValuationClaimedByClientOnDateOfMarriage
    exValuationClaimedByClientOnDateOfAcquisition
    exValuationClaimedByEx
    exValuationClaimedByExOnValuationDate
    exValuationClaimedByExOnDateOfMarriage
    exValuationClaimedByExOnDateOfAcquisition

    value
    valueOnValuationDate
    valueOnDateOfMarriage
    valueOnDateOfAcquisition

    exValuationValue
    exValuationValueOnValuationDate
    exValuationValueOnDateOfMarriage
    exValuationValueOnDateOfAcquisition
  }
`;

export default POSSESSION_FRAGMENT;
