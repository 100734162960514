import { useCallback } from "react";

import { useMutation } from "@apollo/client";

import UPDATE_FINANCES_SUPPORT from "graphql/mutations/finances/updateFinancesSupport";
import UPSERT_FINANCES from "graphql/mutations/finances/upsertFinances";
import CLIENT_FINANCES from "graphql/queries/client/clientFinances";

const useUpdateFinancesSupport = () => {
  const [upsertFinances, { loading: upsertFinancesLoading }] = useMutation(UPSERT_FINANCES);
  const [updateFinancesSupport, { loading: updateFinancesSupportLoading, ...options }] =
    useMutation(UPDATE_FINANCES_SUPPORT);

  const handleUpdateFinancesSupport = useCallback(
    async ({ clientId, finances, taxYear, scenario, calculationId }) => {
      if (!finances) {
        await upsertFinances({
          variables: {
            data: {
              employmentStatus: "EMPLOYED",
            },
            where: {
              taxYear,
              clientId,
              isEx: false,
            },
          },
          update: (cache, { data }) => {
            const createdFinances = data?.upsertFinances;

            const clientFinances = cache.readQuery({
              query: CLIENT_FINANCES,
              variables: { where: { id: clientId }, taxYearWhere: { taxYear } },
            });

            if (clientFinances) {
              cache.writeQuery({
                query: CLIENT_FINANCES,
                variables: { where: { id: clientId }, taxYearWhere: { taxYear } },
                data: {
                  client: {
                    ...(clientFinances.client || {}),
                    finances: [createdFinances],
                  },
                },
              });
            }
          },
        });
      }

      await updateFinancesSupport({
        variables: {
          where: { clientId, isEx: false, taxYear },
          data: {
            supportCalculationId: calculationId,
            supportCalculationScenario: scenario,
          },
        },
      });
    },
    [updateFinancesSupport, upsertFinances],
  );

  return [
    handleUpdateFinancesSupport,
    {
      ...options,
      loading: upsertFinancesLoading || updateFinancesSupportLoading,
    },
  ];
};

export default useUpdateFinancesSupport;
