import { CALCULATOR_ROUTES } from "constants/common";

import React, { useEffect, lazy, Suspense, useCallback, useMemo, useState } from "react";

import { useQuery, useMutation } from "@apollo/client";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { scroller } from "react-scroll";
import { IntercomProvider } from "react-use-intercom";

import VERIFY_EMAIL from "graphql/mutations/user/verifyEmail";
import ME from "graphql/queries/user/me";

import MeContextProvider from "context/MeContext/MeContextProvider";

import Loading from "components/common/Loading";
import CreateDocumentCounselModal from "components/modals/documents/CreateDocumentCounselModal";
import CreateDocumentCourtModal from "components/modals/documents/CreateDocumentCourtModal";
import CreateDocumentLocationModal from "components/modals/documents/CreateDocumentLocationModal";
import CreateDocumentModal from "components/modals/documents/CreateDocumentModal";

import useConfirm from "hooks/useConfirm";

import getNewToken from "utils/getNewToken";

const PrivateRoutes = lazy(() => import("layout/Routes/PrivateRoutes"));
const PublicRoutes = lazy(() => import("layout/Routes/PublicRoutes"));
const ReportRoutes = lazy(() => import("layout/Routes/ReportRoutes"));

const Routes = () => {
  const [isRefreshing, setIsRefreshing] = useState(process.env.NODE_ENV !== "development");

  const { data, loading, refetch } = useQuery(ME, { fetchPolicy: "network-only" });
  const user = useMemo(() => data?.me || {}, [data]);

  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  const { hash, pathname } = useLocation();
  const { push } = useHistory();

  const notification = useConfirm();

  const handleRefreshToken = useCallback(async () => {
    await getNewToken();

    const { data: response } = await refetch();

    setIsRefreshing(false);

    if (!response?.me?.id) {
      localStorage.removeItem("authToken");

      push("/sign-in");
    }
  }, [push, refetch]);

  useEffect(
    () => {
      const token = localStorage.getItem("verify-email");

      if (token && user.id) {
        if (!user?.isEmailVerified) {
          verifyEmail({ variables: { data: { token } } })
            .then(() => push(pathname, { isVerified: true }))
            .catch(() => {
              localStorage.removeItem("verify-email");
              push(pathname);
            });
        }

        localStorage.removeItem("verify-email");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  useEffect(() => {
    if (localStorage.getItem("hasError")?.includes("true")) {
      notification({
        type: "ERROR",
        message:
          "Divorcepath reloaded your calculation because of a connection error. Please try your calculation again, and contact support if the problem continues.",
        noButtons: true,
        showOk: true,
      });
    }

    localStorage.removeItem("hasError");
  }, [notification]);

  useEffect(
    () => {
      const isDefineToken = !!localStorage.getItem("authToken");

      if (!isDefineToken) setIsRefreshing(false);

      if (!data?.me && !loading && isDefineToken) {
        handleRefreshToken();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  useEffect(() => {
    const isCalculatorRoute = CALCULATOR_ROUTES.find((route) => pathname.includes(route));

    if (!loading && !user && !isRefreshing && isCalculatorRoute) {
      localStorage.removeItem("authToken");

      const path = pathname.includes("spousal-support")
        ? `${process.env.REACT_APP_CALCULATION_REDIRECT_LINK}/spousal-support-calculator`
        : `${process.env.REACT_APP_CALCULATION_REDIRECT_LINK}/child-support-calculator`;

      document.location.replace(path);
    }
  }, [isRefreshing, loading, pathname, user]);

  useEffect(() => {
    if (data?.me?.id) setIsRefreshing(false);
  }, [data]);

  useEffect(() => {
    const element = document.getElementById(hash);

    if (element) {
      setTimeout(() => {
        scroller.scrollTo(hash, {
          duration: 1000,
          smooth: "easeInOutQuart",
          offset: -50,
        });
      }, 1000);
    }
  }, [hash]);

  if (pathname.includes("support-report")) {
    return (
      <Suspense fallback={<Loading page />}>
        <ReportRoutes />
      </Suspense>
    );
  }

  if ((loading && !user.id) || isRefreshing) return <Loading page />;

  if (!loading && user.id && pathname === "/upgrade") {
    return <Redirect to="/profile/billing" />;
  }

  const authURLforZapier = localStorage.getItem("authURLforZapier");

  if (!loading && user.id && authURLforZapier) {
    localStorage.removeItem("authURLforZapier");

    return <Redirect to={authURLforZapier} />;
  }

  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
      <MeContextProvider data={data} loading={loading} refetch={refetch}>
        <CreateDocumentModal />
        <CreateDocumentCourtModal />
        <CreateDocumentCounselModal />
        <CreateDocumentLocationModal />

        {user.id ? (
          <Suspense fallback={<Loading page />}>
            <PrivateRoutes />
          </Suspense>
        ) : (
          <Suspense fallback={<Loading page />}>
            <PublicRoutes />
          </Suspense>
        )}
      </MeContextProvider>
    </IntercomProvider>
  );
};

export default Routes;
