import React, { useCallback, useMemo } from "react";

import classnames from "classnames";
import RSelect from "react-select";

import { UPGRADE_PLAN_MODAL_NAME } from "components/modals/upgrade/UpgradePlanModal";

import useCalculationContext from "hooks/useCalculationContext";
import useModal from "hooks/useModal";

const Select = ({
  name,
  value,
  className,
  width = "w-100",
  options,
  onChange,
  placeholder,
  label,
  defaultValue,
  onFocus,
  onBlur,
  disabled,
  isMulti,
  isClearable,
  containerClassName,
  size = "lg", // 'sm' | 'md' | 'lg' (default: 'lg')
  hasError,
  isTouched,
  isRequired,
  hint,
  hintComponent,
  ...props
}) => {
  const { open } = useModal(UPGRADE_PLAN_MODAL_NAME);
  const { isSubscriptionActive } = useCalculationContext();

  const selectClassName = classnames("custom-select-input w-100", className, {
    "is-invalid": hasError && isTouched,
    [`size-${size}`]: size,
  });

  const errorMessageStyles = classnames("invalid-feedback fade", {
    show: hasError && isTouched,
  });

  const selectedValue = useMemo(() => {
    if (!value) return null;
    if (isMulti) return value;

    const currentValue = options?.find((opt) => opt?.value === value);

    return {
      value,
      label: currentValue?.name || currentValue?.label,
    };
  }, [isMulti, options, value]);

  const handleSelect = useCallback(
    (selectValue) => {
      if (
        !isSubscriptionActive &&
        !selectValue?.isPremiumAvailable &&
        selectValue?.isPremiumAvailable !== undefined
      ) {
        open();
      }

      if (onChange) onChange(selectValue);
    },
    [isSubscriptionActive, open, onChange],
  );

  const optionsMapped = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        label: option?.name || option?.label,
        value: option?.value,
        isDisabled: option?.isDisabled,
      })),
    [options],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      className={classnames("form-group custom-input select-input", width, containerClassName)}
      onClick={(e) => e.stopPropagation()}
    >
      {label && (
        <label className="form-control-label" style={{ marginBottom: "0.2rem" }}>
          {label} {isRequired && <span className="required-field">*</span>}
        </label>
      )}

      <RSelect
        onChange={handleSelect}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        placeholder={placeholder}
        name={name}
        className={selectClassName}
        classNamePrefix="custom-select"
        options={optionsMapped}
        value={props?.loadOptions ? value : selectedValue}
        defaultValue={defaultValue || value}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />

      <div className={errorMessageStyles}>{hasError && isTouched && hasError}</div>

      {hintComponent && hintComponent}
      {hint && <small className="form-text text-muted mt-1">{hint}</small>}
    </div>
  );
};

export default Select;
