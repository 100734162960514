import { gql } from "@apollo/client";

const CREATE_DOCUMENT = gql`
  mutation createDocument($data: CreateDocumentInput!) {
    createDocument(data: $data) {
      id
      templateId
      name
      category
      jurisdiction
      createdAt
      updatedAt
      url
      uploadUrl
    }
  }
`;

export default CREATE_DOCUMENT;
