import { gql } from "@apollo/client";

import LAWYER from "graphql/sanity/fragments/lawyer";

const ALL_LAWYERS = gql`
  ${LAWYER}
  query allLawyers($where: LawyerFilter, $sort: [LawyerSorting!]) @api(name: "sanity") {
    allLawyer(where: $where, sort: $sort) {
      ...LAWYER
    }
  }
`;

export default ALL_LAWYERS;
