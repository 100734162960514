import React, { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";

import ALL_PROVINCE from "graphql/sanity/allProvince";

import Select from "./Select";
import SelectField from "./SelectField";

const ProvinceSelect = ({
  name = "residence",
  placeholder = "Select Province",
  disabled,
  isLoading,
  isFormikField,
  ...props
}) => {
  const { data, loading } = useQuery(ALL_PROVINCE);

  const options = useMemo(() => {
    const provinces = data?.allProvince?.map(({ name, _id, shorthand }) => ({
      label: name,
      value: shorthand,
      id: _id,
    }));

    return sortBy(provinces, "label");
  }, [data]);

  const selectProps = useMemo(() => {
    return {
      name,
      placeholder,
      options,
      disabled,
      isLoading: loading || isLoading,
      ...props,
    };
  }, [disabled, isLoading, loading, name, options, placeholder, props]);

  if (isFormikField) {
    return <SelectField {...selectProps} />;
  }

  return <Select {...selectProps} />;
};

export default ProvinceSelect;
