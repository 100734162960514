const getVariablesForJurisdictionQuery = () => {
  const where = {
    _: {
      is_draft: false,
    },
  };

  return {
    whereCountry: where,
    whereProvince: where,
  };
};

export default getVariablesForJurisdictionQuery;
