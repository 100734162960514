import { gql } from "@apollo/client";

const EX_LAWYER = gql`
  fragment EX_LAWYER on Lawyer {
    id
    sanityId
    name
    orgName
    location {
      id
      phone
      postal
      residence
      country
      street1
      street2
      website
      googleReviewLink
      city
      email
      fax
      courtId
      courtLocationId
    }
  }
`;

export default EX_LAWYER;
