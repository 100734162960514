import { isArray, isDate, isObject } from "lodash";

const assignObjectValuesToSet = (object = {}) => {
  const assigned = Object.entries(object).map(([key, value = null]) => {
    if (!isDate(value) && (isObject(value) || isArray(value))) return [key, value];

    return [key, { set: value }];
  });

  return Object.fromEntries(assigned);
};

export default assignObjectValuesToSet;
