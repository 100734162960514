const getNewToken = () => {
  const token = localStorage.getItem("authToken");

  return fetch(process.env.REACT_APP_REFRESH_TOKEN_URL, {
    method: "POST",
    credentials: "include",
    ...(process.env.REACT_APP_ENV_NAME === "development" &&
      token && {
        headers: {
          Authorization: token,
        },
      }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res?.accessToken) localStorage.setItem("authToken", res.accessToken);

      return res?.accessToken;
    });
};

export default getNewToken;
