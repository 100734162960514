import { DOMAIN } from "constants/common";

import { useCallback, useEffect } from "react";

import { useApolloClient } from "@apollo/client";

import ME from "graphql/queries/user/me";

import revokeToken from "utils/revokeToken";

import { gaLogout } from "analytics/google";

const useLogout = ({ refetch }) => {
  const apolloClient = useApolloClient();

  const logout = useCallback(() => {
    revokeToken();
    gaLogout();

    document.cookie = `user=; domain=${DOMAIN}; Path=/;`;
    localStorage.removeItem("authToken");
    localStorage.removeItem("isNotified");
    localStorage.removeItem("detailsOpen");

    apolloClient.writeQuery({
      query: ME,
      data: {
        me: null,
      },
    });

    apolloClient.cache.reset();

    if (window.Intercom) window.Intercom("shutdown");
  }, [apolloClient]);

  const storage = useCallback(
    (e) => {
      const isAuth = e.key === "authToken";

      if (isAuth && !e.newValue && e.oldValue) {
        logout();
      }

      if (isAuth && e.newValue && !e.oldValue) {
        refetch();
      }
    },
    [logout, refetch],
  );

  useEffect(() => {
    window.addEventListener("storage", storage);

    return () => window.removeEventListener("storage", storage);
  }, [storage]);

  return logout;
};

export default useLogout;
