import { gql } from "@apollo/client";

import FINANCES from "graphql/fragments/finances";
import INCOME from "graphql/fragments/income";

const CLIENT_FINANCES = gql`
  ${INCOME}
  ${FINANCES}
  query clientFinances($where: ClientWhereUniqueInput!, $taxYearWhere: WhereTaxYearInput) {
    client(where: $where) {
      income(where: $taxYearWhere) {
        ...INCOME
      }
      exIncome(where: $taxYearWhere) {
        ...INCOME
      }
      finances(where: $taxYearWhere) {
        ...FINANCES
      }
      exFinances(where: $taxYearWhere) {
        ...FINANCES
      }
      adjustments(where: $taxYearWhere) {
        id
        type
        amount
        taxYear
      }
      exAdjustments(where: $taxYearWhere) {
        id
        type
        amount
        taxYear
      }
      childExpenses {
        id
        type
        total
        totalDeductions
        amountByClient
        amountByEx
        childId
        clientId
      }
    }
  }
`;

export default CLIENT_FINANCES;
