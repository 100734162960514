import { gql } from "@apollo/client";

const ALL_JURISDICTIONS = gql`
  query allJurisdictions($whereCountry: CountryFilter, $whereProvince: ProvinceFilter)
  @api(name: "sanity") {
    allCountry(where: $whereCountry) {
      _id
      name
      shorthand
    }

    allProvince(where: $whereProvince) {
      _id
      name
      shorthand
    }
  }
`;

export default ALL_JURISDICTIONS;
