import { useCallback, useEffect } from "react";

import { useLocation } from "react-router";
import { useIntercom } from "react-use-intercom";

import getCompany from "utils/getCompany";
import getPhone from "utils/getPhone";
import getSubscriptionType from "utils/getSubscriptionType";
import getUserName from "utils/getUserName";

const { Intercom } = window || {};

const excludedRoutes = ["/wopi"];

const useBootIntercom = () => {
  const { boot } = useIntercom();
  const { pathname } = useLocation();

  const handleBoot = useCallback(
    (me) => {
      const { id, professional, email = null } = me || {};

      const name = getUserName(me, false);
      const subscriptionType = getSubscriptionType(me);
      const phone = getPhone(me);
      const company = getCompany(me);

      const role = professional?.type || "CLIENT";

      if (id && name) {
        boot({
          name,
          email,
          phone,
          role,
          subscriptionType,
          customAttributes: {
            user_id: id,
            app_id: process.env.REACT_APP_INTERCOM_ID,
            mouseflow: window.mouseflow?.getSessionId() || null,
            custom_launcher_selector: "#intercom_link",
            company,
          },
        });
      }
    },
    [boot],
  );

  useEffect(() => {
    if (Intercom) {
      Intercom("update", {
        hide_default_launcher: !!excludedRoutes.find((route) => pathname.includes(route)),
      });
    }
  }, [pathname]);

  return handleBoot;
};

export default useBootIntercom;
