import dayjs from "dayjs";

import getDateInUTC from "../getDateInUTC/getDateInUTC";

const formatDate = ({ date, utc = false, template = "YYYY-DD-MM" } = {}) => {
  const correctedDate = utc ? getDateInUTC(date) : dayjs(date);

  return correctedDate.format(template);
};

export default formatDate;
