import { useMutation } from "@apollo/client";

import CREATE_DOCUMENT from "graphql/mutations/documents/createDocument";
import CLIENT from "graphql/queries/client/client";
import CLIENT_DOCUMENTS from "graphql/queries/client/clientDocuments";

const useCreateDocument = ({ client, options } = {}) => {
  const [createDocument, result] = useMutation(CREATE_DOCUMENT, options);

  const handleCreateDocument = (otherOptions) => {
    const refetchQueries = [
      // TODO: variables are different now
      { query: CLIENT_DOCUMENTS, variables: { where: { clientId: client?.id } } },
      { query: CLIENT, variables: { where: { id: client?.id } } },
    ];

    if (otherOptions?.refetchQueries) {
      refetchQueries.push(...otherOptions.refetchQueries);
    }

    return createDocument({ ...otherOptions, refetchQueries });
  };

  return [handleCreateDocument, result];
};

export default useCreateDocument;
