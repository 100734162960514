const getVariablesForAllTemplatesQuery = ({ category, courts = [] }) => {
  if (category) {
    return {
      where: {
        category: {
          name: {
            eq: category,
          },
        },
      },
    };
  }

  return {
    where: {
      court: {
        _id: {
          in: courts.map((court) => court._id),
        },
      },
    },
  };
};

export default getVariablesForAllTemplatesQuery;
