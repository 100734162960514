import { DOMAIN } from "constants/common";

import getUserName from "utils/getUserName";

const setUserCookie = (me) => {
  const name = getUserName(me);

  const { email, subscription } = me || {};

  const user = {
    name,
    email,
    subscription,
  };

  document.cookie = `user=${JSON.stringify(user)}; domain=${DOMAIN}; Path=/;`;
};

export default setUserCookie;
