import { gql } from "@apollo/client";

const DOCUMENT = gql`
  fragment DOCUMENT on Document {
    id
    extractionInfo {
      extractedAt
      extractionStatus
    }
    info {
      courtName
      description
      documentType
      filingDate
      formName
      formNumber
      formYear
      province
      source
    }
    templateId
    name
    metadata
    source
    url
    createdById
    category
    extension
    jurisdiction
    isSupportRecalculation
    createdAt
    updatedAt
  }
`;

export default DOCUMENT;
