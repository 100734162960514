import * as yup from "yup";

export const string = yup.string().nullable().required("Required field");

export const validationSchema = yup.object().shape({
  phone: string,
  residence: string,
  street1: string,
  postal: string,
  city: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .nullable()
    .required("Required field"),
});

export const defaultValues = {
  residence: "AB",
  street1: "",
  city: "",
  postal: "",
  phone: "",
  courtId: "",
  courtLocationId: "",
  googleReviewLink: "",
};

export const formInputs = [
  {
    name: "street1",
    placeholder: "Address *",
  },
  {
    name: "phone",
    placeholder: "Phone *",
  },
];
