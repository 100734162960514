import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const getDateInUTC = (date) => {
  return dayjs(date || new Date()).utc();
};

export default getDateInUTC;
