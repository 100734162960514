import { useCallback } from "react";

import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import SIGN_UP from "graphql/mutations/auth/signUp";
import ME from "graphql/queries/user/me";

const useSignUp = () => {
  const [signUp] = useMutation(SIGN_UP);

  const apolloClient = useApolloClient();

  const handleSignUp = useCallback(
    async (variables) => {
      try {
        const { data } = await signUp({ variables });

        localStorage.setItem("authToken", data?.signUp.token);
        localStorage.removeItem("isProfessional");

        apolloClient.writeQuery({
          query: ME,
          data: {
            me: data?.signUp?.user,
          },
        });
      } catch (err) {
        console.error(err);

        if (err instanceof Error) {
          toast.error(err.message);
        } else {
          err.graphQLErrors.map(({ message }) => toast.error(message));
        }
      }
    },
    [apolloClient, signUp],
  );

  return handleSignUp;
};

export default useSignUp;
