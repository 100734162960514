import React, { useState } from "react";

import CustomHelmet from "components/calculations/components/CustomHelmet";
import CreateDocumentForm from "components/clients/forms/CreateDocumentForm";
import Modal from "components/common/Modal";

import useModal from "hooks/useModal";

export const TEMPLATE_CATEGORY_MAP = {
  Precedents: "Agreement",
};

export const CREATE_DOCUMENT_MODAL_NAME = "CREATE_DOCUMENT";

const CreateDocumentModal = () => {
  const [jurisdiction, setJurisdiction] = useState();
  const [isShowJurisdiction, setIsShowJurisdiction] = useState(false);

  const { options } = useModal(CREATE_DOCUMENT_MODAL_NAME);

  const toggleInput = () => setIsShowJurisdiction((prev) => !prev);

  const modalLabel = jurisdiction ? `New ${jurisdiction} Form` : "New Document";

  return (
    <Modal
      size="md"
      name={CREATE_DOCUMENT_MODAL_NAME}
      titleComponent={
        <div className="custom-modal-title">
          <span>
            {modalLabel}
            {options?.defaultCategory && ` (${TEMPLATE_CATEGORY_MAP[options.defaultCategory]})`}
          </span>
          <div onClick={toggleInput} role="button" tabIndex={0} className="jurisdiction-toggle">
            Change Jurisdiction
          </div>
        </div>
      }
      submitButtonTitle="Create"
      className="create-document"
      noFooter
      showCloseButton
    >
      <CustomHelmet
        title={modalLabel}
        description={modalLabel}
        href="https://www.divorcepath.com/app/[new-document]"
        isCanonical
      />
      <CreateDocumentForm
        setJurisdiction={setJurisdiction}
        isShowJurisdiction={isShowJurisdiction}
        setIsShowJurisdiction={setIsShowJurisdiction}
      />
    </Modal>
  );
};

export default CreateDocumentModal;
