import map from "lodash/map";

const regionNames = {
  Canada: "CDN",
  Alberta: "AB",
  Ontario: "ON",
  "British Columbia": "BC",
  Quebec: "QC",
  "Newfoundland and Labrador": "NL",
  "Prince Edward Island": "PE",
  "Nova Scotia": "NS",
  "New Brunswick": "NB",
  Manitoba: "MB",
  Saskatchewan: "SK",
  Yukon: "YT",
  "Northwest Territories": "NT",
  Nunavut: "NU",
  "Outside Canada": "OC",
};

export const regionsKeys = {
  AB: "Alberta",
  BC: "British Columbia",
  CDN: "Canada",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  NT: "Northwest Territories",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
  OC: "Outside Canada",
};

export const provinceOptions = map(regionNames, (value, label) => ({ value, label }));

export default regionNames;
