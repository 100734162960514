import { gql } from "@apollo/client";

import EX_LAWYER from "graphql/fragments/exLawyer";

const UPDATE_LAWYER = gql`
  ${EX_LAWYER}
  mutation updateLawyer(
    $where: UpdateLawyerWhereInput!
    $data: UpdateLawyerInput!
    $updateSanity: Boolean
  ) {
    updateLawyer(where: $where, data: $data, updateSanity: $updateSanity) {
      ...EX_LAWYER
    }
  }
`;

export default UPDATE_LAWYER;
