import dayjs from "dayjs";

import getDateInUTC from "../getDateInUTC/getDateInUTC";

const getDateDiff = ({ date, diffDate, utc = false, unit } = {}) => {
  const utcDate = utc ? getDateInUTC(date) : dayjs(date);
  const utcDiffDate = utc ? getDateInUTC(diffDate) : dayjs(diffDate);

  return utcDate.diff(utcDiffDate, unit);
};

export default getDateDiff;
