import React, { useCallback, useEffect, useMemo, useState } from "react";

import classnames from "classnames";
import { useFormikContext, getIn } from "formik";
import RSCreatableSelect from "react-select/creatable";

// import customStyles from 'components/common/inputs/Select/styles';

const CreatableSelect = ({
  name,
  value,
  className,
  containerClassName,
  options,
  handleChange,
  label,
  defaultValue,
  disabled,
  isClearable = false,
  onCreate,
  isRequired,
  isMulti,
  size = "lg", // 'sm' | 'md' | 'lg' (default: 'lg')
  isStringSelect = false,
  ...props
}) => {
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext();

  const [inputValueState, setInputValueState] = useState("");
  const [optionsState, setOptionsState] = useState(options);

  const selectedValue = useMemo(() => {
    if (isStringSelect) {
      const stringValue = getIn(values, name);

      if (stringValue) return { label: stringValue, value: stringValue };

      return null;
    }

    return value || getIn(values, name) || null;
  }, [name, values, value, isStringSelect]);

  const hasError = useMemo(() => getIn(errors, name, false), [name, errors]);
  const isTouched = useMemo(() => getIn(touched, name, false), [name, touched]);

  const selectClassName = useMemo(
    () =>
      classnames("w-100", "custom-select-input", className, {
        "is-invalid": hasError && isTouched,
        "multi-select": isMulti,
        [`size-${size}`]: size,
      }),
    [className, hasError, isTouched, isMulti, size],
  );

  const errorMessageStyles = useMemo(
    () =>
      classnames("invalid-feedback fade", {
        show: hasError && isTouched,
      }),
    [hasError, isTouched],
  );

  const handleInputChange = useCallback(
    (inputValue) => {
      setInputValueState(inputValue);
      props?.handleInputChange?.(inputValue);
    },
    [props],
  );

  const handleSelect = useCallback(
    (selectValue) => {
      if (isStringSelect) setFieldValue(name, selectValue ? selectValue.value : "");
      else setFieldValue(name, selectValue || []);

      setFieldTouched(name, true, false);
    },
    [name, setFieldTouched, setFieldValue, isStringSelect],
  );

  const handleCreate = useCallback(
    (inputValue) => {
      const newOption = { value: inputValue, label: inputValue };

      setOptionsState([...optionsState, newOption]);

      if (isStringSelect) setFieldValue(name, inputValue);
      else setFieldValue(name, isMulti ? [...(selectedValue || []), newOption] : newOption);

      setInputValueState("");
    },
    [isMulti, name, optionsState, setFieldValue, selectedValue, isStringSelect],
  );

  const optionsMapped = useMemo(
    () =>
      optionsState.map((option) => ({
        ...option,
        label: option?.name || option?.label,
        value: option?.value,
        isDisabled: option?.isDisabled,
      })),
    [optionsState],
  );

  useEffect(() => setOptionsState(options || []), [options]);

  return (
    <div className={classnames("form-group custom-input select-input w-100", containerClassName)}>
      {label && (
        <label className="form-control-label" style={{ marginBottom: "0.2rem" }}>
          {label} {isRequired && <span className="required-field">*</span>}
        </label>
      )}

      <RSCreatableSelect
        {...props}
        isClearable={isClearable}
        isDisabled={disabled}
        name={name}
        className={selectClassName}
        classNamePrefix="custom-select"
        options={optionsMapped}
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={handleChange || handleSelect}
        inputValue={inputValueState}
        onCreateOption={onCreate || handleCreate}
        onInputChange={handleInputChange}
        // styles={customStyles}
        isMulti={isMulti}
      />

      <div className={errorMessageStyles}>{hasError && isTouched && hasError}</div>
    </div>
  );
};

export default CreatableSelect;
