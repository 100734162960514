import { useCallback } from "react";

import { useApolloClient, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import SIGN_IN from "graphql/mutations/auth/signIn";
import ME from "graphql/queries/user/me";

import { gaLogin, gaLoginFailed } from "analytics/google";

import useVerifyEmail from "./useVerifyEmail";

const useSignIn = () => {
  const { push } = useHistory();

  const [signIn] = useMutation(SIGN_IN);

  const verifyEmail = useVerifyEmail();

  const apolloClient = useApolloClient();

  const handleSignIn = useCallback(
    async (variables) => {
      let event = "login";

      try {
        const { data } = await signIn({ variables });

        localStorage.setItem("authToken", data?.signIn?.token);
        localStorage.removeItem("isProfessional");

        apolloClient.writeQuery({
          query: ME,
          data: {
            me: data?.signIn.user,
          },
        });

        const isEmailVerified = data?.signIn?.user?.isEmailVerified;

        verifyEmail(isEmailVerified);

        if (!isEmailVerified) push("/", { isVerified: true });
      } catch (err) {
        event = "login_failed";

        console.error(err);

        if (err instanceof Error) {
          toast.error(err.message);
        } else {
          err.graphQLErrors.map(({ message }) => toast.error(message));
        }
      } finally {
        if (event === "login") gaLogin();
        else gaLoginFailed();
      }
    },
    [apolloClient, signIn, push, verifyEmail],
  );

  return handleSignIn;
};

export default useSignIn;
