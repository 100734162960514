import { gql } from "@apollo/client";

const INCOME = gql`
  fragment INCOME on Income {
    id
    amount
    job {
      id
      since
      name
    }
    type
    taxYear
  }
`;

export default INCOME;
