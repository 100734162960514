import gql from "graphql-tag";

const ORGANIZATION_ISSUE = gql`
  fragment ORGANIZATION_ISSUE on OrganizationIssue {
    id
    name
    type
    description
  }
`;

export default ORGANIZATION_ISSUE;
