const getCompany = (me) => {
  const { professional } = me || {};

  if (!professional?.id) {
    return null;
  }

  return {
    id: professional?.organization?.id || null,
    name: professional?.organization?.name || null,
  };
};

export default getCompany;
