import { useCallback } from "react";

import { useMutation } from "@apollo/client";

import VERIFY_EMAIL from "graphql/mutations/user/verifyEmail";

const useVerifyEmail = () => {
  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  const handleVerifyEmail = useCallback(
    async (isEmailVerified) => {
      const token = localStorage.getItem("verify-email");

      if (token) {
        if (!isEmailVerified) {
          await verifyEmail({ variables: { data: { token } } });
        }

        localStorage.removeItem("verify-email");
      }
    },
    [verifyEmail],
  );

  return handleVerifyEmail;
};

export default useVerifyEmail;
