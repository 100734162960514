import { trafficType } from "constants/common";

import { ANALYTICS_PLANS } from "modules/plans/constants";

import getCompany from "utils/getCompany";
import getPhone from "utils/getPhone";
import getSubscriptionType from "utils/getSubscriptionType";
import getUserName from "utils/getUserName";

const dataLayer = window.dataLayer || [];

/* =================
  Events 
  ================= */
export const gaBeginCheckout = (planCode) => {
  dataLayer.push({ ecommerce: null });

  const plan = ANALYTICS_PLANS[planCode];

  dataLayer.push({
    event: "begin_checkout",
    traffic_type: trafficType,
    ecommerce: {
      items: plan,
    },
  });
};

export const gaUpdateSubscription = (accountSubscription) => {
  const tagManagerArgs = {
    event: "update_subscription",
    subscription: accountSubscription.plan.id,
    status: accountSubscription.status,
    isPro: accountSubscription.plan.isPro,
  };

  dataLayer.push({ ecommerce: null });
  dataLayer.push(tagManagerArgs);
};

export const gaViewItemList = (pro) => {
  dataLayer.push({ ecommerce: null });

  const planList = Object.values(ANALYTICS_PLANS).filter((plan) => plan.pro === pro);

  dataLayer.push({
    event: "view_item_list",
    traffic_type: trafficType,
    ecommerce: {
      items: planList,
    },
  });
};

export const gaAddToCart = (planCode) => {
  dataLayer.push({ ecommerce: null });

  const plan = ANALYTICS_PLANS[planCode];

  dataLayer.push({
    event: "add_to_cart",
    traffic_type: trafficType,
    ecommerce: {
      items: plan,
    },
  });
};

export const gaRecoverPassword = () => {
  dataLayer.push({
    event: "recover_password",
    traffic_type: trafficType,
  });
};

export const gaRecoverPasswordFailed = () => {
  dataLayer.push({
    event: "recover_password_failed",
    traffic_type: trafficType,
  });
};

export const gaResetPassword = () => {
  dataLayer.push({
    event: "reset_password",
    traffic_type: trafficType,
  });
};

export const gaLogin = () => {
  dataLayer.push({
    event: "login",
    method: "email",
    traffic_type: trafficType,
  });
};

export const gaLoginFailed = () => {
  dataLayer.push({
    event: "login_failed",
    method: "email",
    traffic_type: trafficType,
  });
};

export const gaLogout = () => {
  dataLayer.push({
    event: "logout",
    traffic_type: trafficType,
  });
};

export const gaUserSession = (me) => {
  const { id, professional, email = null } = me || {};

  const name = getUserName(me, false);
  const subscription = getSubscriptionType(me);
  const phone = getPhone(me);
  const company = getCompany(me);

  const role = professional?.type || "CLIENT";

  if (id && name) {
    const tagManagerArgs = {
      user: {
        id,
        name,
        subscription,
        role,
        phone,
        email,
        company,
        mouseflow: window.mouseflow?.getSessionId() || null,
      },
    };

    dataLayer.push(tagManagerArgs);
  }
};
