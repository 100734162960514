import React, { useMemo } from "react";

import Helmet from "react-helmet";

import useMe from "hooks/useMe";

const CustomHelmet = ({ title, description, href, isCanonical }) => {
  const { isPro, me } = useMe();

  const organization = useMemo(
    () => (isPro ? `- ${me?.professional?.organization?.name}` : ""),
    [isPro, me?.professional?.organization?.name],
  );

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} ${organization} - Divorcepath`}
      meta={[{ name: "description", content: description }]}
      link={[{ href, rel: isCanonical ? "canonical" : "nofollow" }]}
    />
  );
};

export default CustomHelmet;
