import React, { useEffect } from "react";

import classnames from "classnames";
import { Link } from "react-router-dom";

import Button from "components/common/Button";
import Modal from "components/common/Modal";
import FeaturesList from "components/modals/upgrade/FeaturesList";

import useMe from "hooks/useMe";
import useModal from "hooks/useModal";

export const UPGRADE_PLAN_MODAL_NAME = "UPGRADE_PLAN";

const UpgradePlanModal = () => {
  const { isPro, isActiveSub, loading, refetch } = useMe();

  const { isOpen, close } = useModal(UPGRADE_PLAN_MODAL_NAME);

  useEffect(() => {
    if (!isActiveSub && !loading) {
      refetch();
    }
  }, [isActiveSub, loading, refetch, isOpen]);

  if (isActiveSub) {
    return null;
  }

  return (
    <Modal
      autoFocus={false}
      size="lg"
      name={UPGRADE_PLAN_MODAL_NAME}
      className={classnames("upgrade-plan-modal", { pro: isPro, client: !isPro })}
      title={isPro ? "Upgrade for Pro Tools" : "Upgrade for Premium Features"}
      showCloseButton
      cancelBtnTitle="Not Now"
      borderedHeader={false}
      borderedFooter={false}
      returnFocusAfterClose={false}
      customOkBtn={
        <Button
          color="primary"
          size="lg"
          onClick={close}
          type="button"
          tag={Link}
          to="/plans"
          className="btn-submit"
        >
          Choose Plan
        </Button>
      }
    >
      <FeaturesList />
    </Modal>
  );
};

export default UpgradePlanModal;
