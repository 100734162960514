import { gql } from "@apollo/client";

const INCOME_EARNERS = gql`
  fragment INCOME_EARNERS on OtherIncomeEarners {
    id
    livesWithOtherAdults
    newPartner
    partnerName
    partnerIncome
    partnerContribution
    otherAdultsNames
    otherAdultsIncome
    otherAdultsContribution
  }
`;

export default INCOME_EARNERS;
