import gql from "graphql-tag";
import DOCUMENT from "graphql/fragments/document";

const CLIENT_DOCUMENTS = gql`
  ${DOCUMENT}
  query clientDocuments(
    $where: WhereDocumentInput!
    $first: Int
    $after: String
    $orderBy: [DocumentOrderByWithRelationInput!]
  ) {
    documentsConnection(where: $where, first: $first, after: $after, orderBy: $orderBy) {
      totalCount
      edges {
        cursor
        node {
          ...DOCUMENT
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default CLIENT_DOCUMENTS;
