import { useMemo } from "react";

import regionNames from "components/calculations/utils/regionNames";

import useMe from "hooks/useMe";

/**
 * @param {string} residence Residence's "Shorthand" or "Name"
 * @returns Residence's "Shorthand"
 */
const normalizeResidence = (residence) => {
  return regionNames[residence] || residence;
};

const useCourtInformation = (client) => {
  const { me } = useMe();

  const meLocations = useMemo(
    () => me?.professional?.locations || [],
    [me?.professional?.locations],
  );

  const orgLocations = useMemo(
    () => me?.professional?.organization?.locations || [],
    [me?.professional?.organization?.locations],
  );

  /* =======================================================
    Default existing residence to work with in order:
    1. Residence from Client's "COURT"
    2. Residence from Client's "LOCATION"
    3. Residence from Client's "ADDRESS"
    4. Residence from User's default location 
    5. Residence from Org's default location
    ==================================================== */
  const residence = useMemo(() => {
    const meLocationWithResidence = meLocations.find((location) => location.residence);
    const orgLocationWithResidence = orgLocations.find((location) => location.residence);

    return normalizeResidence(
      client?.court?.residence ||
        client?.proLocation?.residence ||
        client?.address?.residence ||
        meLocationWithResidence?.residence ||
        orgLocationWithResidence?.residence,
    );
  }, [
    client?.address?.residence,
    client?.court?.residence,
    client?.proLocation?.residence,
    meLocations,
    orgLocations,
  ]);

  /* =======================================================
    Other information in order:
    1. Client's "COURT"
    2. User's default location 
    5. Org's default location
    ==================================================== */
  const courtId = useMemo(() => {
    const meLocationWithCourtId = meLocations.find((location) => location.courtId);
    const orgLocationWithCourtId = orgLocations.find((location) => location.courtId);

    return (
      client?.court?.courtId || meLocationWithCourtId?.courtId || orgLocationWithCourtId?.courtId
    );
  }, [client?.court?.courtId, meLocations, orgLocations]);

  const courtLocationId = useMemo(() => {
    const meLocationWithCourtLocationId = meLocations.find((location) => location.courtLocationId);
    const orgLocationWithCourtLocationId = orgLocations.find(
      (location) => location.courtLocationId,
    );

    return (
      client?.court?.courtLocationId ||
      meLocationWithCourtLocationId?.courtLocationId ||
      orgLocationWithCourtLocationId?.courtLocationId
    );
  }, [client?.court?.courtLocationId, meLocations, orgLocations]);

  const courtFile = useMemo(() => client?.court?.courtFile, [client?.court?.courtFile]);

  return { residence, courtId, courtLocationId, courtFile };
};

export default useCourtInformation;
