/* eslint-disable no-console */
import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import CLIENT_FRAGMENT from "graphql/fragments/client";
import UPDATE_LAWYER from "graphql/sanity/mutations/updateLawyer";

const useUpdateLawyer = (client) => {
  const [updateLawyer, options] = useMutation(UPDATE_LAWYER);

  const handleUpdateLawyer = useCallback(
    async (values) => {
      const { location = {}, sanityId, ...restValues } = values;

      const toastId = toast.loading("Updating Lawyer...");

      try {
        await updateLawyer({
          variables: {
            updateSanity: true,
            where: {
              clientId: client?.id,
            },
            data: {
              ...restValues,
              location,
            },
          },
          update: (cache, { data }) => {
            cache.writeFragment({
              id: `Client:${client?.id}`,
              fragment: CLIENT_FRAGMENT,
              fragmentName: "CLIENT_FRAGMENT",
              data: {
                ...client,
                exLawyer: {
                  ...data.updateLawyer,
                },
              },
            });
          },
        });

        toast.success("Updated Lawyer");
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        toast.dismiss(toastId);
      }
    },
    [client, updateLawyer],
  );

  return [handleUpdateLawyer, options];
};

export default useUpdateLawyer;
