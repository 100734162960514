import gql from "graphql-tag";

const FINANCES = gql`
  fragment FINANCES on Finances {
    id
    taxYear
    employmentStatus
    unemployedSince
    valuationDate
    supportCalculationScenario
    supportCalculation {
      id
      content
      calculationResult {
        id
        content
      }
    }
  }
`;

export default FINANCES;
