import React, { useMemo } from "react";

import classnames from "classnames";
import { connect, getIn } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ButtonGroup } from "reactstrap";

import Icon from "components/common/Icon";
import ToggleButton from "components/common/ToggleButtons/ToggleButton";

const ToggleButtons = ({
  buttons = [],
  disabled,
  formik,
  labelClassName,
  hint,
  hintComponent,
  label,
  name,
  className,
  isRequired,
  isValidate = true,
  labelWithTooltip,
  tooltipText,
  size = "lg",
  type = "default", // default | wide
  value,
  disabledOnClick,
}) => {
  const { errors, touched, isSubmitting } = formik;

  const hasError = useMemo(() => getIn(errors, name, false), [errors, name]);
  const isTouched = useMemo(() => getIn(touched, name, false), [touched, name]);

  return (
    <div className={classnames("form-group custom-toggle-buttons", className)}>
      {!!label && (
        <label
          className={classnames(labelClassName || "form-control-label", {
            "with-tooltip": labelWithTooltip && tooltipText,
          })}
          style={{ marginBottom: "0.2rem" }}
        >
          {label} {isRequired && <span className="required-field">*</span>}
          {labelWithTooltip && tooltipText && (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-top">
                  <p className="mb-0">{tooltipText}</p>
                </Tooltip>
              }
            >
              <Icon name="tooltip-circle" />
            </OverlayTrigger>
          )}
        </label>
      )}
      <ButtonGroup
        className={classnames("custom-btn-group", type)}
        disabled={disabled || isSubmitting}
        name={name}
      >
        {buttons.map((button) => (
          <ToggleButton
            key={button.value}
            button={button}
            formik={formik}
            size={size}
            value={value}
            disabled={disabled}
            disabledOnClick={disabledOnClick}
            name={name}
          />
        ))}
      </ButtonGroup>
      <div
        className={classnames("invalid-feedback fade", {
          show: isValidate && hasError && isTouched,
        })}
      >
        {isValidate && hasError && isTouched && hasError}
      </div>
      {hintComponent}
      {hint && <small className="form-text text-muted mt-1">{hint}</small>}
    </div>
  );
};

export default connect(ToggleButtons);
