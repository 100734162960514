import React from "react";

import classNames from "classnames";

import {
  WordIcon,
  DocxIcon,
  DotxIcon,
  ExcelIcon,
  OdpIcon,
  OdsIcon,
  OdtIcon,
  OfficeIcon as MSOfficeIcon,
  PotxIcon,
  PowerpointIcon,
  PpsxIcon,
  PptIcon,
  PptxIcon,
  VisioIcon,
  VsdxIcon,
  VssxIcon,
  VstxIcon,
  XlsIcon,
  XlsxIcon,
  XltxIcon,
  PdfIcon,
} from "modules/wopi/icons";

import { ReactComponent as AddPersonIcon } from "img/icons/add-person.svg";
import { ReactComponent as AlertIcon } from "img/icons/alert.svg";
import { ReactComponent as ArrowCircleUpIcon } from "img/icons/arrow-circle-up.svg";
import { ReactComponent as ArrowDownIcon } from "img/icons/arrow-down.svg";
import { ReactComponent as ArrowRightIcon } from "img/icons/arrow-right.svg";
import { ReactComponent as ArrowUpIcon } from "img/icons/arrow-up.svg";
import { ReactComponent as ArrowsIcon } from "img/icons/arrows.svg";
import { ReactComponent as BookIcon } from "img/icons/book.svg";
import { ReactComponent as CalculatorIcon } from "img/icons/calculator.svg";
import { ReactComponent as CalendarIcon } from "img/icons/calendar.svg";
import { ReactComponent as CheckCircleSolidIcon } from "img/icons/check-circle-solid.svg";
import { ReactComponent as CheckCircleIcon } from "img/icons/check-circle.svg";
import { ReactComponent as CheckSolidIcon } from "img/icons/check-solid.svg";
import { ReactComponent as CheckStrokeIcon } from "img/icons/check-stroke.svg";
import { ReactComponent as CircleCrossIcon } from "img/icons/circle-cross.svg";
import { ReactComponent as ConfIcon } from "img/icons/conf.svg";
import { ReactComponent as ConfigIcon } from "img/icons/config.svg";
import { ReactComponent as CourtIcon } from "img/icons/court.svg";
import { ReactComponent as CreditCardIcon } from "img/icons/credit-card.svg";
import { ReactComponent as CrossIcon } from "img/icons/cross.svg";
import { ReactComponent as DocumentAddIcon } from "img/icons/document-add.svg";
import { ReactComponent as DocumentDuplicateIcon } from "img/icons/document-duplicate.svg";
import { ReactComponent as DocumentIcon } from "img/icons/document.svg";
import { ReactComponent as DollarIcon } from "img/icons/dollar.svg";
import { ReactComponent as DownloadIcon } from "img/icons/download.svg";
import { ReactComponent as DuplicateIcon } from "img/icons/duplicate.svg";
import { ReactComponent as EditIcon } from "img/icons/edit.svg";
import { ReactComponent as EmailIcon } from "img/icons/email.svg";
import { ReactComponent as EyeOffIcon } from "img/icons/eye-off.svg";
import { ReactComponent as EyeOnIcon } from "img/icons/eye-on.svg";
import { ReactComponent as FamilyIcon } from "img/icons/family.svg";
import { ReactComponent as FAQIcon } from "img/icons/faq.svg";
import { ReactComponent as FileIcon } from "img/icons/file.svg";
import { ReactComponent as HeartIcon } from "img/icons/heart.svg";
import { ReactComponent as HomeIcon } from "img/icons/home.svg";
import { ReactComponent as InfoCircleIcon } from "img/icons/info-circle.svg";
import { ReactComponent as KeyIcon } from "img/icons/key.svg";
import { ReactComponent as ListIcon } from "img/icons/list.svg";
import { ReactComponent as LoaderIcon } from "img/icons/loader.svg";
import { ReactComponent as LogoIcon } from "img/icons/logo.svg";
import { ReactComponent as MergeIcon } from "img/icons/merge.svg";
import { ReactComponent as MinusIcon } from "img/icons/minus.svg";
import { ReactComponent as OfficeIcon } from "img/icons/office.svg";
import { ReactComponent as PercentIcon } from "img/icons/percent.svg";
import { ReactComponent as PhoneIcon } from "img/icons/phone.svg";
import { ReactComponent as PinIcon } from "img/icons/pin.svg";
import { ReactComponent as PlusIcon } from "img/icons/plus.svg";
import { ReactComponent as QuestionMarkIcon } from "img/icons/question-mark.svg";
import { ReactComponent as SaveIcon } from "img/icons/save.svg";
import { ReactComponent as SearchIcon } from "img/icons/search.svg";
import { ReactComponent as SettingsIcon } from "img/icons/settings.svg";
import { ReactComponent as ShareIcon } from "img/icons/share.svg";
import { ReactComponent as SpinnerIcon } from "img/icons/spinner.svg";
import { ReactComponent as StarIcon } from "img/icons/star.svg";
import { ReactComponent as SupportIcon } from "img/icons/support.svg";
import { ReactComponent as TooltipCircleIcon } from "img/icons/tooltip-circle.svg";
import { ReactComponent as TrashIcon } from "img/icons/trash.svg";
import { ReactComponent as TriangleIcon } from "img/icons/triangle.svg";
import { ReactComponent as UpgradeIcon } from "img/icons/upgrade.svg";
import { ReactComponent as UploadIcon } from "img/icons/upload.svg";
import { ReactComponent as UserCircleIcon } from "img/icons/user-circle.svg";
import { ReactComponent as UserIcon } from "img/icons/user.svg";
import { ReactComponent as UsersIcon } from "img/icons/users.svg";
import { ReactComponent as WebhookIcon } from "img/icons/webhook.svg";

export const AVAILABLE_ICONS = {
  word: WordIcon,
  docx: DocxIcon,
  dotx: DotxIcon,
  excel: ExcelIcon,
  odp: OdpIcon,
  ods: OdsIcon,
  odt: OdtIcon,
  msOffice: MSOfficeIcon,
  potx: PotxIcon,
  powerpoint: PowerpointIcon,
  ppsx: PpsxIcon,
  ppt: PptIcon,
  pptx: PptxIcon,
  visio: VisioIcon,
  vsdx: VsdxIcon,
  vssx: VssxIcon,
  vstx: VstxIcon,
  xls: XlsIcon,
  xlsx: XlsxIcon,
  xltx: XltxIcon,
  pdf: PdfIcon,

  "add-person": AddPersonIcon,
  "arrow-circle-up": ArrowCircleUpIcon,
  "arrow-down": ArrowDownIcon,
  "arrow-up": ArrowUpIcon,
  "arrow-right": ArrowRightIcon,
  "check-circle": CheckCircleIcon,
  "check-circle-solid": CheckCircleSolidIcon,
  "check-stroke": CheckStrokeIcon,
  "credit-card": CreditCardIcon,
  "document-add": DocumentAddIcon,
  "document-duplicate": DocumentDuplicateIcon,
  "eye-off": EyeOffIcon,
  "eye-on": EyeOnIcon,
  "info-circle": InfoCircleIcon,
  "user-circle": UserCircleIcon,
  "check-solid": CheckSolidIcon,
  "question-mark": QuestionMarkIcon,
  "tooltip-circle": TooltipCircleIcon,
  alert: AlertIcon,
  book: BookIcon,
  calculator: CalculatorIcon,
  calendar: CalendarIcon,
  config: ConfigIcon,
  court: CourtIcon,
  cross: CrossIcon,
  document: DocumentIcon,
  dollar: DollarIcon,
  edit: EditIcon,
  email: EmailIcon,
  faq: FAQIcon,
  file: FileIcon,
  heart: HeartIcon,
  home: HomeIcon,
  minus: MinusIcon,
  office: OfficeIcon,
  phone: PhoneIcon,
  plus: PlusIcon,
  percent: PercentIcon,
  save: SaveIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  share: ShareIcon,
  spinner: SpinnerIcon,
  star: StarIcon,
  support: SupportIcon,
  trash: TrashIcon,
  triangle: TriangleIcon,
  user: UserIcon,
  users: UsersIcon,
  download: DownloadIcon,
  upload: UploadIcon,
  loader: LoaderIcon,
  merge: MergeIcon,
  arrows: ArrowsIcon,
  family: FamilyIcon,
  logo: LogoIcon,
  webhook: WebhookIcon,
  duplicate: DuplicateIcon,
  key: KeyIcon,
  conf: ConfIcon,
  pin: PinIcon,
  list: ListIcon,
  upgrade: UpgradeIcon,
  "circle-cross": CircleCrossIcon,
};

const Icon = ({ name, defaultName, className, spin, ...props }) => {
  const IconByType = AVAILABLE_ICONS[name] || AVAILABLE_ICONS[defaultName];

  if (!IconByType) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconByType name={name} className={classNames("icon", { spin }, className)} {...props} />;
};

export default Icon;
