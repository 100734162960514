import React from "react";

import { components } from "react-select";

const LawyerSelectOption = ({ children, ...props }) => {
  const { city, orgName } = props.data || {};

  return (
    <components.Option {...props}>
      <div className="custom-option">
        <p className="option-text text-limit mb-0">{children}</p>
        {(city || orgName) && (
          <div className="option-description">
            <span>{city}</span>
            <span>{orgName}</span>
          </div>
        )}
      </div>
    </components.Option>
  );
};

export default LawyerSelectOption;
