const getProfileName = (profile, emailAsDefault = true) => {
  const { firstName, middleName, lastName, email } = profile || {};

  let name = "";

  if (firstName) {
    name += firstName;
  }

  if (middleName) {
    name += ` ${middleName}`;
  }

  if (lastName) {
    name += ` ${lastName}`;
  }

  if (name.length) {
    return name;
  }

  if (emailAsDefault && email) {
    return email;
  }

  return "";
};

export default getProfileName;
