import React from "react";

const initialValues = {
  me: {},
  isSubscribed: false,
  isActiveSub: false,
  isUnpaidSub: false,
  isActiveTrial: false,
  isOrgAdmin: false,
  isOrgFounder: false,
  isOrgMember: false,
  isPro: false,
  loading: true,
  signIn: () => ({}),
  signUp: () => ({}),
  logout: () => ({}),
  refetch: () => ({}),
};

const MeContext = React.createContext(initialValues);

export default MeContext;
