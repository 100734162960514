import React, { useEffect, useMemo } from "react";

import MeContext from "context/MeContext/MeContext";

import useLogout from "hooks/me/useLogout";
import useRegenerateSubscription from "hooks/me/useRegenerateSubscription";
import useSignIn from "hooks/me/useSignIn";
import useSignUp from "hooks/me/useSignUp";
import useBootIntercom from "hooks/useBootIntercom";

import setUserCookie from "utils/cookie/setUserCookie";
import determineOrganizationUserAffiliation from "utils/me/determineOrganizationUserAffiliation";
import determineUserSubscription from "utils/me/determineUserSubscription";

import { gaUserSession } from "analytics/google";

const MeContextProvider = ({ children, data, loading, refetch }) => {
  const { me = {} } = data || {};
  const { subscription = {}, professional = {} } = me || {};
  const { organization } = professional || {};

  const signIn = useSignIn();
  const signUp = useSignUp();
  const logout = useLogout({ refetch });

  const bootIntercom = useBootIntercom();

  useRegenerateSubscription({ me, refetch });

  useEffect(() => {
    setUserCookie(me);
    gaUserSession(me);
    bootIntercom(me);
  }, [bootIntercom, me]);

  /* ========================
    Checks
    ===================== */
  const isPro = useMemo(() => professional && professional.id, [professional]);

  const { isOrgAdmin, isOrgFounder, isOrgMember } = useMemo(
    () => determineOrganizationUserAffiliation({ organization, me }),
    [me, organization],
  );

  const { isSubscribed, isActiveSub, isUnpaidSub, isActiveTrial, isTrialAvailable, seatsQuantity } =
    useMemo(
      () => determineUserSubscription({ organization, subscription, isOrgFounder }),
      [organization, subscription, isOrgFounder],
    );

  const value = useMemo(
    () => ({
      me,
      isSubscribed,
      isActiveSub,
      isUnpaidSub,
      isActiveTrial,
      isTrialAvailable,
      isOrgAdmin,
      isOrgFounder,
      isOrgMember,
      isPro,
      seatsQuantity,
      loading,
      signIn,
      signUp,
      logout,
      refetch,
    }),
    [
      me,
      isSubscribed,
      isActiveSub,
      isUnpaidSub,
      isActiveTrial,
      isTrialAvailable,
      isOrgAdmin,
      isOrgFounder,
      isOrgMember,
      seatsQuantity,
      isPro,
      loading,
      signIn,
      signUp,
      logout,
      refetch,
    ],
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};

export default MeContextProvider;
