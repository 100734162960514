export const ACTIVE_SUBSCRIPTION_STATUSES = ["ACTIVE", "TRIALING", "INCOMPLETE"];
export const UNPAID_SUBSCRIPTION_STATUSES = [
  "INCOMPLETE_EXPIRED",
  "PAST_DUE",
  "CANCELED",
  "UNPAID",
  "PAUSED",
];

const determineUserSubscription = ({ organization, subscription, isOrgFounder }) => {
  const founder = organization?.members?.find(
    (member) => organization?.createdBy?.id === member?.user?.id,
  );

  const statuses = [founder?.user?.subscription?.status, subscription?.status];

  /* All org members have subscription by default, if "Founder" has it too */
  return {
    isSubscribed: !![...ACTIVE_SUBSCRIPTION_STATUSES, ...UNPAID_SUBSCRIPTION_STATUSES].find(
      (correct) => statuses.find((status) => correct === status),
    ),

    isUnpaidSub: !!UNPAID_SUBSCRIPTION_STATUSES.find((correct) =>
      statuses.find((status) => correct === status),
    ),

    isActiveSub: !!ACTIVE_SUBSCRIPTION_STATUSES.find((correct) =>
      statuses.find((status) => correct === status),
    ),

    isActiveTrial: statuses.includes("TRIALING"),

    isTrialAvailable: isOrgFounder && !subscription,

    seatsQuantity: founder?.user?.subscription?.quantity || 1,
  };
};

export default determineUserSubscription;
