import React from "react";

export const PLAN_PERIODS = {
  pro: ["Annually", "Monthly"],
  client: ["1 Month", "6 Month"],
};

/* ======================
  Maps
  ==================== */
export const PLAN_PERIOD_MAP = {
  ProToolsYear: "Annually",
  ProToolsMonth: "Monthly",
  CHILD_SUPPORT_1: "1 Month",
  CHILD_SPOUSAL_SUPPORT_1: "1 Month",
  CHILD_SUPPORT_6: "6 Month",
  CHILD_SPOUSAL_SUPPORT_6: "6 Month",
};

export const LEGACY_PLAN_NAME_MAP = {
  FIRM_1: "Firm",
  SOLO_1: "Solo",
};

/* ======================
  Buttons
  ===================== */
export const PRO_PERIOD_BUTTONS = [
  {
    text: "Monthly",
    value: PLAN_PERIOD_MAP.ProToolsMonth,
  },
  {
    text: (
      <React.Fragment>
        Annually <span>20% OFF</span>
      </React.Fragment>
    ),
    value: PLAN_PERIOD_MAP.ProToolsYear,
  },
];

export const CLIENT_PERIOD_BUTTONS = [
  {
    text: "1 Month",
    value: PLAN_PERIOD_MAP.CHILD_SPOUSAL_SUPPORT_1,
  },
  {
    text: "6 Month ",
    value: PLAN_PERIOD_MAP.CHILD_SPOUSAL_SUPPORT_6,
  },
];

/* ======================
  Analytics
  ===================== */
export const ANALYTICS_PLANS = {
  ProToolsYear: {
    item_id: "ProToolsYear",
    item_name: "Advanced",
    currency: "CAD",
    price: 576,
    trial: false,
    pro: true,
    recurring: true,
  },
  ProToolsMonth: {
    item_id: "ProToolsMonth",
    item_name: "Advanced",
    currency: "CAD",
    price: 60,
    trial: false,
    pro: true,
    recurring: true,
  },
  CHILD_SUPPORT_1: {
    item_id: "CHILD_SUPPORT_1",
    item_name: "Child Support",
    currency: "CAD",
    price: 19,
    trial: false,
    pro: false,
    recurring: false,
  },
  CHILD_SUPPORT_6: {
    item_id: "CHILD_SUPPORT_6",
    item_name: "Child Support",
    currency: "CAD",
    price: 29,
    trial: false,
    pro: false,
    recurring: false,
  },
  CHILD_SPOUSAL_SUPPORT_1: {
    item_id: "CHILD_SPOUSAL_SUPPORT_1",
    item_name: "Child & Spousal Support",
    currency: "CAD",
    price: 39,
    trial: false,
    pro: false,
    recurring: false,
  },
  CHILD_SPOUSAL_SUPPORT_6: {
    item_id: "CHILD_SPOUSAL_SUPPORT_6",
    item_name: "Child & Spousal Support",
    currency: "CAD",
    price: 59,
    trial: false,
    pro: false,
    recurring: false,
  },
};
