const formInputs = [
  {
    name: "orgName",
    label: "Organization Name",
    placeholder: "Organization Name",
  },
  {
    name: "location.country",
    label: "Country",
    placeholder: "Country",
  },
  {
    name: "location.residence",
    label: "Province",
    placeholder: "Select Residence",
    containerClassName: "mb-3",
  },
  {
    name: "location.city",
    label: "City",
    placeholder: "City",
  },
  {
    name: "location.street1",
    label: "Address Line",
    placeholder: "Address Line",
  },
  {
    name: "location.street2",
    label: "Address Line 2",
    placeholder: "Address Line 2",
  },
  [
    {
      name: "location.postal",
      label: "Postal Code",
      placeholder: "Postal code or Zip Code",
    },
    {
      name: "location.fax",
      label: "Fax",
      placeholder: "01-2345-6789",
      containerClassName: "mb-4",
    },
  ],
  [
    {
      name: "location.email",
      label: "Email",
      placeholder: "Email",
      type: "Email",
    },
    {
      name: "location.phone",
      label: "Phone Number",
      placeholder: "+1-234-567-8900",
    },
  ],
];

export default formInputs;
