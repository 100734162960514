import React from "react";

import Counsel from "components/ClientProfile/forms/counsel/Counsel";
import Modal from "components/common/Modal";

import useCreateDocumentFlow from "hooks/useCreateDocumentFlow";
import useModal from "hooks/useModal";

export const CREATE_DOCUMENT_COUNSEL_MODAL_NAME = "DOCUMENT_COUNSEL";

const CreateDocumentCounselModal = () => {
  const { close, options = {} } = useModal(CREATE_DOCUMENT_COUNSEL_MODAL_NAME);

  const createDocumentFlow = useCreateDocumentFlow(3, { client: options.client });

  const handleSubmit = () => {
    createDocumentFlow(options);

    close();
  };

  return (
    <Modal
      size="md"
      name={CREATE_DOCUMENT_COUNSEL_MODAL_NAME}
      titleComponent={<div className="custom-modal-title">Opposing Counsel (Optional)</div>}
      className="create-document-counsel"
      noFooter
      showCloseButton
    >
      <Counsel user={{ client: options?.client }} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default CreateDocumentCounselModal;
