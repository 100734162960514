const determineOrganizationUserAffiliation = ({ organization, me }) => {
  const { id } = me || {};

  const member = organization?.members?.find((member) => member.user.id === id);

  return {
    isOrgFounder: organization?.createdBy?.id === id,
    isOrgAdmin: member?.role === "ADMIN",
    isOrgMember: !!member,
  };
};

export default determineOrganizationUserAffiliation;
