import gql from "graphql-tag";

const RELATIONSHIP = gql`
  fragment RELATIONSHIP on Relationship {
    id
    relationshipStart
    cohabitationDate
    isMarried
    isSeparated
    isDivorced
    marriageDate
    marriagePlace
    amicability
    separationDate
    divorceDate
  }
`;

export default RELATIONSHIP;
