const getInitialValues = (lawyer) => {
  return {
    sanityId: lawyer?._id || lawyer?.sanityId || "",
    name: lawyer?.name || "",
    orgName: lawyer?.orgName || "",
    location: {
      phone: lawyer?.phone || lawyer?.location?.phone || "",
      postal: lawyer?.postal || lawyer?.location?.postal || "",
      country: lawyer?.country || lawyer?.location?.country || "",
      residence: lawyer?.residence?.shorthand || lawyer?.location?.residence || null,
      street1: lawyer?.street1 || lawyer?.location?.street1 || "",
      street2: lawyer?.street2 || lawyer?.location?.street2 || "",
      city: lawyer?.city || lawyer?.location?.city || "",
      email: lawyer?.email || lawyer?.location?.email || "",
      fax: lawyer?.fax || lawyer?.location?.fax || "",
    },
  };
};

export default getInitialValues;
